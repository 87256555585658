import React, { useEffect } from "react"

import { Router, Route } from "@reach/router"
import NotFoundPage from "../404";
import SendPasswordResetPage from './SendPasswordResetPage'
import ResetPasswordPage from './ResetPasswordPage'
import { withPrefix } from "gatsby";

const ResetPassword = (props) => {

    return (
        <Router basepath={withPrefix("/resetpassword")}>
            <ResetPasswordPage {...props} path={"/:email/:key"} />
            <SendPasswordResetPage {...props} path="/"/>
            
            <NotFoundPage default />
        </Router>
    )
}

export default ResetPassword
