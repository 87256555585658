import * as React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"

import * as generalStyles from "../../scss/privacy.module.scss"
import * as styles from "../../scss/privacy.module.scss"
import * as formStyles from "../../scss/form.module.scss"
import * as btnStyles from "../../components/buttons/buttons.module.scss"
import axios from "axios"
import { navigate } from "@reach/router"
import { BsEye, BsEyeSlash } from "react-icons/all";


const ResetPasswordPage = () => {
    const shapeFirst = useRef(null)
    const shapeSecond = useRef(null)
    const shapeThird = useRef(null)
    const [password, setPassword] = useState('');
    const [showInvCred, setShowInvCred] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm()
    const [showPass, setShowPass] = useState(true);


    useEffect(() => {

        const shapeFistOffsetTop = shapeFirst.current.offsetTop

        const shapeSecondOffsetTop = shapeSecond.current.offsetTop

        window.addEventListener("scroll", () => {
            if (shapeFirst.current) {
                shapeFirst.current.style.top =
                    shapeFistOffsetTop + window.scrollY / 3 + "px"
                shapeSecond.current.style.top =
                    shapeSecondOffsetTop - window.scrollY / 3 + "px"
            } else {
                window.removeEventListener("scroll", () => { })
            }
        })
    })


    const doSubmit = ({ password }) => {

        let config = {
            method: 'post',
            url: 'https://detoxme.at/wp/wp-json/siegfried/v1/auth',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                password
            }
        };

        axios(config)
            .then((response) => {
                if (response.data.c == 200) {
                    navigate('./success');
                }
                else if (response.data.c == 403) {
                    setShowInvCred(true);
                }
            }
            )
            .catch(function (error) {
                console.log(error);
            });

    }



    return (
        <Layout>
            <SEO title="Imprint" />
            <div className={generalStyles.responsiveWrapper}>

                <div className={styles.container}>
                    <form className={formStyles.form} onSubmit={handleSubmit(doSubmit)}>

                        <p style={{ marginBottom: " 5px" }}>Neues Passwort</p>
                        <div className={formStyles.passwordInput}>
                            <input
                                type={showPass ? "password" : "text"}
                                name="password"
                                className={formStyles.input}
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                                placeholder="************"
                                ref={register({
                                    required: true,
                                    // pattern: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/i,
                                })}
                            />
                            <button type="button" onClick={() => { setShowPass(!showPass) }}>
                                {showPass ? <BsEye /> : <BsEyeSlash />}
                            </button>
                        </div>

                        {errors.password && (
                            <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px" }}>
                                Passwort ist erforderlich und mindestens acht Zeichen, mindestens
                                ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl
                            </p>
                        )}

                        <input style={{ marginTop: " 25px" }}
                            type="submit"
                            className={`${btnStyles.button} ${btnStyles.buttonBorder}`}
                            value="Passwort speichern"
                        />

                        {showInvCred && (
                            <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px" }}>
                                Deine Emailadresse und/oder Passwort ist/sind falsch.
                            </p>
                        )}
                    </form>
                </div>

                <div style={{ height: 40, backgroundColor: "#E4F8EF", width: "100%", position: "absolute", bottom: 0, left: 0, display: "block" }}></div>
            </div>
        </Layout>
    )
}

export default ResetPasswordPage